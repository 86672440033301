import React, { useState } from 'react'

import {
  Button,
  Buttons,
  ErrorIcon,
  Heading,
  Modal,
  ModalBody,
  Paragraph
} from '@te-digi/styleguide'

const WarningModalExample = () => {
  const [isOpen, setIsOpen] = useState(false)

  const onModalOpen = () => setIsOpen(isModalOpen => !isModalOpen)
  const onModalClose = () => setIsOpen(false)

  return (
    <>
      <Button onClick={onModalOpen}>Näytä dialogi</Button>
      <Modal
        ariaLabelledby="modal-heading"
        centered
        isOpen={isOpen}
        size="lg"
      >
        <ModalBody>
          <Heading
            icon={<ErrorIcon color="danger" />}
            id="modal-heading"
            size={3}
          >
            Työpaikkailmoituksen tallennus ei onnistu
          </Heading>
          <Paragraph>
            Työpaikkailmoituksen tallennus ei onnistu, sillä se on arkistoitu.
            Poistu työpaikkailmoituksen muokkauksesta. Työpaikkailmoitus löytyy
            arkistoiduista ilmoituksista.
          </Paragraph>
          <Buttons
            align="right"
            noMargin
          >
            <Button onClick={onModalClose}>Sulje</Button>
          </Buttons>
        </ModalBody>
      </Modal>
    </>
  )
}

export { WarningModalExample }
