import React from 'react'

import {
  Button,
  Buttons,
  Heading,
  ModalBody,
  ModalContent,
  Paragraph,
  Stack
} from '@te-digi/styleguide'

const ModalExample3 = () => (
  <Stack gap="lg">
    <ModalContent size="lg">
      <ModalBody>
        <Heading size={4}>
          Haluatko jatkaa selvityksen täyttämistä myöhemmin?
        </Heading>
        <Paragraph>
          Selvityksen tiedot ovat tallennettu.
          <br />
          Et ole lähettänyt selvitystä. Lähetä selvitys viimeistään 15.2.2019.
        </Paragraph>
        <Buttons
          align="right"
          noMargin
        >
          <Button variant="outline">Peruuta</Button>
          <Button>Jatka myöhemmin</Button>
        </Buttons>
      </ModalBody>
    </ModalContent>
    <ModalContent size="lg">
      <ModalBody>
        <Heading size={4}>Lähetetäänkö selvitys?</Heading>
        <Paragraph>
          Kun selvitys on lähetetty, se otetaan käsittelyyn ja et voi enää
          muokata vastauksiasi.
          <br />
          Selvitys käsitellään antamiesi vastausten ja lisäämiesi liitteiden
          perusteella.
        </Paragraph>
        <Buttons
          align="right"
          noMargin
        >
          <Button variant="outline">Peruuta</Button>
          <Button color="secondary">Lähetä</Button>
        </Buttons>
      </ModalBody>
    </ModalContent>
    <ModalContent size="lg">
      <ModalBody>
        <Heading size={4}>
          Ei verkkoyhteyttä. Selvityksen lähetys ei onnistunut.
        </Heading>
        <Buttons
          align="right"
          noMargin
        >
          <Button variant="outline">Sulje</Button>
          <Button>Yritä uudelleen</Button>
        </Buttons>
      </ModalBody>
    </ModalContent>
    <ModalContent size="lg">
      <ModalBody>
        <Heading size={4}>Sinulla on 15 minuutta aikaa vastata</Heading>
        <Paragraph>
          Palautuspäivä umpeutuu 15 minuutin kuluttua. Tämän jälkeen et voi enää
          lähettää vastaustasi. Halutessasi voit siirtää palautuspäivää.
        </Paragraph>
        <Buttons
          align="right"
          noMargin
        >
          <Button variant="outline">Siirrä palautuspäivää</Button>
          <Button>Jatka</Button>
        </Buttons>
      </ModalBody>
    </ModalContent>
    <ModalContent size="lg">
      <ModalBody>
        <Heading size={4}>Palautuspäivä umpeutui</Heading>
        <Paragraph>
          Et voi enää täyttää tai lähettää selvitystä, koska palautuspäivä
          umpeutui.
          <br />
          Selvitys on otettu käsittelyyn ilman vastausta.
        </Paragraph>
        <Buttons
          align="right"
          noMargin
        >
          <Button>Sulje</Button>
        </Buttons>
      </ModalBody>
    </ModalContent>
    <ModalContent size="lg">
      <ModalBody>
        <Heading size={4}>
          Oletko varma, että haluat poistaa tutkinnon "Tutkinto X"?
        </Heading>
        <Buttons
          align="right"
          noMargin
        >
          <Button variant="outline">Peruuta</Button>
          <Button color="danger">Poista tutkinto</Button>
        </Buttons>
      </ModalBody>
    </ModalContent>
  </Stack>
)

export { ModalExample3 }
