import React, { useState } from 'react'

import {
  Button,
  Buttons,
  Heading,
  Modal,
  ModalBody,
  Paragraph
} from '@te-digi/styleguide'

import lorem from '../../lorem'

const ModalExample2 = () => {
  const [isOpen, setIsOpen] = useState(false)

  const onModalOpen = () => setIsOpen(isModalOpen => !isModalOpen)
  const onModalClose = () => setIsOpen(false)

  return (
    <>
      <Button onClick={onModalOpen}>Näytä dialogi</Button>
      <Modal
        ariaLabelledby="modal-heading"
        centered
        isOpen={isOpen}
        size="lg"
      >
        <ModalBody>
          <Heading
            id="modal-heading"
            size={4}
          >
            {lorem({ words: 5 })}
          </Heading>
          <Paragraph>{lorem({ sentences: 2 })}</Paragraph>
          <Buttons
            align="right"
            noMargin
          >
            <Button onClick={onModalClose}>Sulje</Button>
          </Buttons>
        </ModalBody>
      </Modal>
    </>
  )
}

export { ModalExample2 }
