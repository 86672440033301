import React from 'react'

import {
  DescriptionList,
  DescriptionListItem,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Paragraph,
  Spacer
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { ModalExample1 } from '../../examples/react/ModalExample1'
import { ModalExample2 } from '../../examples/react/ModalExample2'
import { ModalExample3 } from '../../examples/react/ModalExample3'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { WarningModalExample } from '../../examples/react/WarningModalExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Modal"
    components={[
      { component: Modal, restElement: 'ReactModal' },
      { component: ModalBody },
      { component: ModalHeader },
      { component: ModalHeading, restElement: 'Heading' },
      { component: ModalFooter }
    ]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          <Code>Modal</Code> estää väliaikaisesti päänäkymän käyttämisen, kunnes
          käyttäjä on reagoinut dialogiin tai sulkenut sen.
        </ListItem>
        <ListItem>
          Käytetään:
          <List
            variant="unordered"
            noMargin
          >
            <ListItem>varmistamaan toiminnon suorittaminen,</ListItem>
            <ListItem>
              tarjoamaan erillinen lomakekenäkymä tai toiminto, poistumatta
              sivulta,
            </ListItem>
            <ListItem>
              esittämään muuta oleellista tietoa joka vaatii käyttäjän
              reagointia.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Dialogin leveyksiä on neljä eri variaatiota:
          <DescriptionList
            variant="striped"
            noMargin
          >
            <DescriptionListItem
              label="Oletus"
              labelWidth={2}
            >
              500px
            </DescriptionListItem>
            <DescriptionListItem
              label={<Code>sm</Code>}
              labelWidth={2}
            >
              300px
            </DescriptionListItem>
            <DescriptionListItem
              label={<Code>lg</Code>}
              labelWidth={2}
            >
              800px
            </DescriptionListItem>
            <DescriptionListItem
              label={<Code>xl</Code>}
              labelWidth={2}
            >
              1140px
            </DescriptionListItem>
          </DescriptionList>
        </ListItem>
        <Spacer marginBottom="sm" />
        <ListItem>
          Otsikossa ilmaistaan asia lyhyesti. Asia tarkennetaan otsikon jälkeen
          lyhyesti, selkeästi ja jämäkästi Sinä-muodossa.
        </ListItem>
        <ListItem>
          Modaalin otsikko tulee olla otsikkotasoa (<Code>level</Code>) 1,
          otsikkotyyliä (<Code>size</Code>) 3. <Code>ModalHeading</Code> tekee
          tämän automaattisesti.
        </ListItem>
        <ListItem>
          Dialogissa voi olla painikkeita yksi tai useampi. Painikkeissa
          käytetään yleisiä painiketyylejä. Secondary-panikkeessa toistetaan
          pääkysymyksen termi, esim. Lähetetäänkö → Lähetä.
        </ListItem>
        <ListItem>
          Dialogissa kysyttävä toiminto peruutetaan Peruuta-painikkeella. Jos
          toimintoja ei ole, niin dialogi suljetaan Sulje-painikkeella.
        </ListItem>
        <ListItem>Dialogi tulee keskelle näyttöä.</ListItem>
        <ListItem>
          Useita dialogeja ei saa laittaa sisäkkäin ja vain yksi dialogi saa
          olla näkyvillä kerralla.
        </ListItem>
      </List>
    </Section>
    <Section title="Dialogit ylä- ja alatunnisteilla">
      <Paragraph>
        Ylä- ja alatunnistetta käytetään aina, kun kyse ei ole lyhyestä
        varmistusdialogista.
      </Paragraph>
      <Playground example={ModalExample1} />
    </Section>
    <Section title="Varmistus- ja infomodaalit">
      <Playground example={ModalExample2} />
      <Spacer marginBottom="xl" />
      <SectionHeading>Esimerkkejä</SectionHeading>
      <ModalExample3 />
    </Section>
    <Section title="Varoitusmodaali">
      <Playground example={WarningModalExample} />
    </Section>
  </Content>
)

export default Page
