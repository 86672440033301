import React, { useState } from 'react'

import {
  Button,
  FormGroup,
  Lead,
  MailIcon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Paragraph,
  Textarea
} from '@te-digi/styleguide'

import lorem from '../../lorem'

const ModalExample1 = () => {
  const [isModalOpen1, setIsModalOpen1] = useState(false)
  const [value, setValue] = useState('')

  const onModalOpenClick1 = () => setIsModalOpen1(isModalOpen => !isModalOpen)
  const onModalClose1 = () => setIsModalOpen1(false)

  return (
    <>
      <Button onClick={onModalOpenClick1}>Näytä dialogi</Button>
      <Modal
        ariaLabelledby="modal-heading"
        isOpen={isModalOpen1}
        onRequestClose={onModalClose1}
        size="lg"
      >
        <ModalHeader onCloseButtonClick={onModalClose1}>
          <ModalHeading
            id="modal-heading"
            icon={<MailIcon />}
          >
            Lähetä palautetta
          </ModalHeading>
        </ModalHeader>
        <ModalBody>
          <Lead>
            Ennen kuin lähetät palautetta, voit käydä tutustumassa Yleisimmät
            palautteet ja vastaukset -sivuun. Sieltä löydät ratkaisuja
            palautteisiin, joita saamme eniten.
          </Lead>
          <FormGroup>
            <Textarea
              ariaDescribedby="textarea-sublabel-1"
              id="textarea-1"
              label="Sana on vapaa, mikä toimi tai ei toiminut? Millaisia ajatuksia tai tuntemuksia käytön yhteydessä heräsi?"
              onChange={event => setValue(event.target.value)}
              placeholder="Kirjoita tähän palautteesi"
              required
              subLabel="Jos raportoit virheestä, meitä auttaisi, että kerrot mahdollisimman tarkasti mitä olit tekemässä ja mitä tapahtui."
              value={value}
            />
          </FormGroup>
          <Paragraph>{lorem({ sentences: 10 })}</Paragraph>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onModalClose1}
            variant="outline"
          >
            Peruuta
          </Button>
          <Button onClick={onModalClose1}>Tallenna</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export { ModalExample1 }
